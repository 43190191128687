import React, { useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import {
  Show,
  TabbedShowLayout,
  EditButton,
  useRefresh,
  Tab,
  useNotify,
  useRecordContext,
  TabbedShowLayoutTabs,
  SimpleForm,
  TopToolbar,
  Toolbar,
} from 'react-admin';
import {
  Button,
  Dialog,
  DialogTitle,
  CircularProgress,
  Tooltip,
  Box,
  Typography,
  ButtonGroup,
} from '@mui/material';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import ProfileExportSelects from './profile_export/Selects';
import ApplicationTab from './tabs/ApplicationTab';
import ReferencesTab from './tabs/ReferencesTab';
import config from 'config';
import GetApp from '@mui/icons-material/GetAppOutlined';
import PdfButton from 'components/PdfButton';
import { helloSignClient } from 'utils/helloSign';
import UploadItem from 'resources/professionals/UploadItem';
import { CheckCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import useCheckAccess from 'hooks/useCheckAccess';
import { RecommendedAssignmentsTab } from './tabs/RecommendedAssignmentsTab';
import create from 'utils/api/create';
import ResumeTab from 'resources/professionals/show_tabs/ResumeTab';

const RequestActions = () => {
  const record = useRecordContext();
  const [allValues, setAllValues] = useState({ open: false, deleting: false });
  const notify = useNotify();
  const refresh = useRefresh();

  const onSubmit = async (values) => {
    try {
      await create(`${config.pdf_url}/assignment_requests/${record.id}`, values);
      notify('Profile saved');
      setAllValues({ ...allValues, open: false });
    } catch (error) {
      notify((error as any).message, { type: 'warning' });
    }
  };

  const onSubmitPreview = async () => {
    try {
      const { data: response } = await create(
        `/v3/admin/assignment_requests/${record.id}/send_offer`,
        { type: 'offer' },
        'AssignmentRequest',
      );
      helloSignClient.open(response.claim_url);
    } catch (error) {
      const errorMessage =
        (error as any).response.data.message ?? (error as any).message ?? 'Something went wrong';
      notify(errorMessage, { type: 'warning' });
    }
  };

  useEffect(() => {
    const handleOfferSent = () => {
      notify('The signature request was sent');
      refresh();
    };
    helloSignClient.on('send', handleOfferSent);
    return () => helloSignClient.off('send');
  }, [notify, refresh]);

  const onSubmitReminder = async () => {
    try {
      await create(`/v3/admin/assignment_requests/${record.id}/offer_reminder`, {});
      notify('Offer reminder sent');
      refresh();
    } catch (error) {
      const errorMessage =
        (error as any).response.data.message ?? (error as any).message ?? 'Something went wrong';
      notify(errorMessage, { type: 'warning' });
    }
  };

  const onDeleteContract = async () => {
    setAllValues({ ...allValues, deleting: true });
    try {
      await create(`/v3/admin/assignment_requests/${record.id}/delete_contract`, { type: 'offer' });
      notify('Delete Request Successful');
      refresh();
    } catch (error) {
      const errorMessage =
        (error as any).response.data.message ?? (error as any).message ?? 'Something went wrong';
      notify(errorMessage, { type: 'warning' });
    } finally {
      setAllValues({ ...allValues, deleting: false });
    }
  };

  const hasUpload = Boolean(record?.has_upload);
  if (!record?.professional?.id) {
    return null;
  }

  return (
    <TopToolbar>
      <Tooltip disableHoverListener={hasUpload} title="You need to save it first">
        <span>
          <PdfButton
            disabled={!record.has_upload}
            color="primary"
            href={`/assignment_requests/${record?.id}`}
            download={`${String(
              record?.professional?.account?.last_name,
            ).toLowerCase()}_application_${record?.id}.pdf`}
            size="small"
            variant="outlined"
            startIcon={<GetApp />}
          >
            Profile
          </PdfButton>
        </span>
      </Tooltip>
      <Button
        aria-label="export profile"
        variant="outlined"
        onClick={() => setAllValues({ ...allValues, open: true })}
        color="primary"
        size="small"
      >
        Save profile
      </Button>
      {record.assignment_profile.payout_kind === 'w2' &&
        record.assignment.status === 'matched' &&
        ['applied', 'offer_sent'].includes(record.status) && (
          <ButtonGroup size="small">
            {record.assignment.offer === record.professional.id ? (
              <Button
                aria-label="send reminder"
                variant="outlined"
                onClick={onSubmitReminder}
                color="primary"
                size="small"
                disabled={record.assignment.offer === 'signed'}
              >
                Send Offer Reminder
              </Button>
            ) : (
              <Button
                aria-label="send offer"
                variant="outlined"
                onClick={onSubmitPreview}
                color="primary"
                size="small"
                disabled={record.assignment.offer === 'signed'}
              >
                Send Offer Contract
              </Button>
            )}
            <Button
              disabled={!record.assignment.offer || record.assignment.offer === 'signed'}
              onClick={onDeleteContract}
            >
              {allValues.deleting ? <CircularProgress size={20} /> : <DeleteOutline />}
            </Button>
          </ButtonGroup>
        )}
      <EditButton />
      <Dialog
        maxWidth="sm"
        fullWidth
        open={allValues.open}
        onClose={() => setAllValues({ ...allValues, open: false })}
      >
        <DialogTitle>Save travel submission package</DialogTitle>
        <Box mx={3}>
          <Typography>
            {record.professional.has_item ? (
              <CheckCircleOutline fontSize="inherit" color="primary" />
            ) : (
              <RemoveCircleOutline fontSize="inherit" color="error" />
            )}{' '}
            SSN is {!record.professional.has_item ? 'not' : ''} set
          </Typography>
          <UploadItem record={record.professional} />
        </Box>
        <SimpleForm
          onSubmit={onSubmit}
          toolbar={
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button aria-label="export submit" type="submit" variant="contained" color="primary">
                Save
              </Button>
              <Button onClick={() => setAllValues({ ...allValues, open: false })} color="primary">
                Cancel
              </Button>
            </Toolbar>
          }
        >
          <ProfileExportSelects
            professionalId={record.professional.id}
            relevantQualificationIds={record.relevant_qualification_ids}
          />
        </SimpleForm>
      </Dialog>
    </TopToolbar>
  );
};

const AssignmentRequestsShow = () => {
  const checkAccess = useCheckAccess();

  return (
    <Show actions={<RequestActions />}>
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" />}>
        <Tab label="Application" data-testid="application-tab">
          <ApplicationTab />
        </Tab>
        <Tab label="References">
          <ReferencesTab />
        </Tab>
        {checkAccess('view', 'Assignment') && (
          <Tab label="Recommended assignments">
            <RecommendedAssignmentsTab />
          </Tab>
        )}
        <Tab label="Applicant Resume">
          <ResumeTab isApplicantResume />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

AssignmentRequestsShow.query = gql`
  query GET_ONE($id: Int!) {
    assignmentRequest(id: $id) {
      id
      relevant_qualification_ids
      assignment {
        id
        starts_date
        status
        is_w2
        local_only
        offer
        on_hold
        paused
        notes
      }
      assignment_profile {
        payout_kind
      }
      professional_id
      professional {
        id
        account {
          id
          name
          last_name
        }
        profile_strength
        recruiter {
          id
          account {
            id
            name
          }
        }
        resume
        has_item
      }
      status
      submitted_to_vms
      vms_profile_submitted_at
      vms_application_submitted_at
      vms_profile_status
      vms_reject_reason
      vms_application_status
      cam_reject_reason
      created_at
      updated_at
      interview_availability
      professional_prev_worked_here
      time_off_request
      note
      recruiter_notes
      cam_notes
      has_upload
      upload
      bonus_type
      exceptions
      has_contract
      unapply_reason
      unapplied_at
      offer_source
      coordinate_distance
      preferred_start_date
      asap
      referrer_professional {
        id
        account {
          id
          name
        }
      }
      rejection_reason
      submitting_cam {
        id
        name
        administrator {
          id
        }
      }
      contract_type
      has_missing_work_history
      has_unexplained_work_gaps
      has_missing_education
      has_missing_ehr_software
      has_missing_references
      has_missing_availability
      has_missing_identity
      travel_profile_strength
    }
  }
`;

export default AssignmentRequestsShow;
